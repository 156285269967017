( function( $ ) {
	const getCookie = ( cookieName ) => {
		const match = document.cookie.match( `(?:^|; )${cookieName}=([^;]*)` );
		return match ? decodeURIComponent( match[1] ) : null;
	};

	const setCookie = ( name, value, maxAge ) => {
		document.cookie = `${name}=${encodeURIComponent( value )}; path=/; max-age=${maxAge}`;
	};

	$( window ).on( 'load', () => {
		const promoPopupSelector = '#modal-promo';
		const mailchimpEmbedSelector = '.mailchimp-embed';
		const buttonSelector = '.modal-message__btn';
		const contentSelector = '.modal-message__content';
		const popupDisclamer = '.modal-disclamer';
		const btnWrapSelector = '.modal-message__btn-wrap';
		const modalBackdropSelector = '.modal-backdrop';

		let testGroup = getCookie( 'ab_split_promo' );

		if ( !testGroup ) {
			testGroup = Math.random() < 0.5 ? 'ab-var-A' : 'ab-var-B';
			setCookie( 'ab_split_promo', testGroup, 86400 );
		}

		$( 'body' ).addClass( testGroup );

		if ( testGroup === 'ab-var-A' && !getCookie( 'promotionalPopup' ) ) {
			if ( $( promoPopupSelector ).length ) {
				if ( $.fn.modal ) {
					setTimeout( () => {
						$( promoPopupSelector ).modal( 'show' );

						if ( testGroup === 'ab-var-B' ) {
							$( modalBackdropSelector ).css( 'display', 'none' );
						}
					}, 1000 );
				}
			}
			setCookie( 'promotionalPopup', '1', 86400 );
		}

		$( buttonSelector ).on( 'click', () => {
			$( mailchimpEmbedSelector ).fadeIn();
			$( contentSelector ).hide();
			$( btnWrapSelector ).hide();
			$( popupDisclamer ).hide();
		} );
	} );
} )( jQuery );
